import { ElementType, FC } from "react";

import {
  ColorProps,
  LayoutProps,
  SpaceProps,
  TypographyProps
} from "@doar/shared/styled";

import { StyledText } from "./style";

interface IProps extends SpaceProps, ColorProps, TypographyProps, LayoutProps {
    as?: ElementType;
    className?: string;
}

const Text: FC<IProps> = ({ as, className, children, ...restProps }) => {
    return (
        <StyledText as={as} className={className} {...restProps}>
            {children}
        </StyledText>
    );
};

export default Text;
