/* eslint-disable */
import styled from "@doar/shared/styled";

export const StyledLoader = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(250, 250, 250, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 99;
`;
