/* eslint-disable */
import axios from "axios";
/* eslint-disable import/no-unresolved */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Search } from "react-feather";
import { UserContext } from "src/config/userContext";

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "@doar/components";

import { StyledForm } from "./style";
import "./styles.css";

// import { StyledDropDown } from "src/layouts/layout-02/content-header/style";

interface IProps {
  getInput?: any;
  toggleSearch: (value: boolean) => void;
}

const ContentSearch: React.FC<IProps> = (props) => {
  const { user } = useContext(UserContext);
  const { getInput, toggleSearch } = props;
  const [showSearch, setShowSearch] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  function handleSubmit(e: any) {
    e.preventDefault();
  }
  function handleChange(e: any) {
    getInput(e.target.value);
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === ".") {
        event.preventDefault();
        setShowSearch((prev) => !prev);
        toggleSearch(!showSearch);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (showSearch && inputRef.current) {
      inputRef.current.focus(); 
    }
  }, [showSearch]);

  return (
    <>
      {showSearch && (
        <>
          <div className="content-search-overlay" onClick={() => setShowSearch(false)} />
          <div className="content-search-wrapper">
            <StyledForm onSubmit={handleSubmit} className="search-input-header">
              <Search strokeWidth="2.8px" size={20} />
              <input
                type="text"
                id="search-new"
                name="search-new"
                placeholder="Search..."
                onChange={handleChange}
                ref={inputRef}
              />
            </StyledForm>
          </div>
        </>
      )}
    </>
  );
  
};

export default ContentSearch;
