/* eslint-disable */

import { User } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { auth } from "src/config/firebase";

import API from "@doar/shared/methods/api";

import { AuthContext } from "./context";
import { CustomerContext, UserContext } from "./userContext";

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const { userUpdate } = useContext(UserContext);
  const { customerUpdate } = useContext(CustomerContext);

  const botsUrl = `/bots/ids_names`;
  let botsOffset = 0;
  let botsAllData: any = [];
  const customersUrl = `/customers`;
  let customersOffset = 0;
  let customersAllData: any = [];
  const usersUrl = `/users`;
  let usersOffset = 0;
  let usersAllData: any = [];

  async function fetchBotsData() {
    try {
      let initialResponse = await API.get(`${botsUrl}?offset=0`);
      const totalBots = initialResponse.data.pagination.total;
      const pageSize = initialResponse.data.pagination.limit; // Utiliza el límite proporcionado por la primera respuesta

      botsAllData = [...initialResponse.data.data];
      let botsOffset = botsAllData.length;

      // Si la primera carga ya recoge todos los datos, procede a guardarlos y finaliza la función
      if (botsAllData.length >= totalBots) {
        window.sessionStorage.setItem("botList", JSON.stringify(botsAllData));
        return;
      }

      // Genera todas las peticiones necesarias para cubrir todos los datos restantes
      let promises = [];
      while (botsOffset < totalBots) {
        promises.push(API.get(`${botsUrl}?offset=${botsOffset}`));
        botsOffset += pageSize;
        
      }

      // Espera y procesa todas las peticiones
      let results = await Promise.all(promises);
      results.forEach((result) => {
        botsAllData = [...botsAllData, ...result.data.data];
      });

      // Almacena los datos finales
      window.sessionStorage.setItem("botList", JSON.stringify(botsAllData));
    } catch (error) {
      console.error(error);
    }
  }

  function fetchUsersData() {
    API.get(`${usersUrl}?offset=${usersOffset}`)
      .then((response: any) => {
        // console.log("users chunk data", response.data);

        const data = response.data.data;
        usersAllData = [...usersAllData, ...data];
        usersOffset += data.length;

        if (usersAllData.length < response.data.pagination.total) {
          // if there is more data, fetch the next page
          fetchUsersData();
        } else {
          // if there is no more data, log the results
          // console.log("merged users data", usersAllData);
          window.sessionStorage.setItem("userList", JSON.stringify(usersAllData));
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  async function fetchCustomersData() {
    try {
      // Inicia la primera petición para obtener el total de clientes y el límite de paginación
      let initialResponse = await API.get(`${customersUrl}?offset=0`);
      const totalCustomers = initialResponse.data.pagination.total;
      const pageSize = initialResponse.data.pagination.limit; // Usar el límite de la API como tamaño de página

      // Guarda los datos iniciales
      customersAllData = [...initialResponse.data.data];
      let customersOffset = customersAllData.length;

      // Si la primera carga ya contiene todos los datos, almacena el resultado y termina
      if (customersAllData.length >= totalCustomers) {
        window.sessionStorage.setItem("customerList", JSON.stringify(customersAllData));
        return;
      }

      // Prepara las peticiones subsiguientes si aún hay más datos por cargar
      let promises = [];
      while (customersOffset < totalCustomers) {
        promises.push(API.get(`${customersUrl}?offset=${customersOffset}`));
        customersOffset += pageSize; // Incrementa el offset por el tamaño de página devuelto
      }

      // Espera a que todas las peticiones se completen y procesa los datos
      let results = await Promise.all(promises);
      results.forEach((result) => {
        customersAllData = [...customersAllData, ...result.data.data];
      });

      // Almacena los datos finales
      window.sessionStorage.setItem("customerList", JSON.stringify(customersAllData));
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (user) {
      API.get(`/users/${user.uid}?expand_customers=true`)
        .then((res) => {
          let response = res.data;

          userUpdate(res.data);

          const cookie = window.sessionStorage.getItem("customerSelected");
          // console.log("COOKIE >>", cookie, typeof cookie);

          if (cookie === null || cookie === "undefined") {
            customerUpdate(response.customers[0]);
          } else {
            customerUpdate(JSON.parse(cookie));
          }
          if (response?.user?.role === "ADMIN") {
            // Para cuando necesitemos la lista de usuarios
            // fetchUsersData();
            fetchCustomersData();

            fetchBotsData();
          }
        })
        .catch((err) => console.log(err));
    }

    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
    });

    return unsubscribe;
  }, [user]);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};
