/* eslint-disable */
import { EntityMetadata } from "./actions/extract_entity";
import { LanguageEnum } from "./language";
import { EntityType } from "./data";
import { IStep } from "./steps";
import { VoiceSettings } from "./voice";

export enum BotType {
  RESTAURANT = "RESTAURANT",
  FLEXBOT = "FLEXBOT",
  IVR = "IVR", // Legacy, will be deprecated
}

export enum IntegrationType {
  GOLFMANAGER = "GOLFMANAGER",
  RESTOO = "RESTOO",
  COVERMANAGER = "COVERMANAGER",
  EMAIL_SENDGRID = "EMAIL_SENDGRID",
  SMS_TWILIO = "SMS_TWILIO",
  SMS_NETELIP = "SMS_NETELIP",
  WHATSAPP_360D = "WHATSAPP_360D",
  WHATSAPP_META = "WHATSAPP_META",
  GOOGLE_MAPS = "GOOGLE_MAPS",
  COLZENT = "COLZENT",
  DENTALINK = "DENTALINK",
  NIS = "NIS",
  HOLDED = "HOLDED",
  HUBSPOT = "HUBSPOT",
  CLOSE = "CLOSE",
  MIXPANEL_INGESTION = "MIXPANEL_INGESTION",
  CHATGPT = "CHATGPT",
  DRSMILE = "DRSMILE",
  REVITAE = "REVITAE",
  BCN3D = "BCN3D",
  GOOGLE_SHEETS = "GOOGLE_SHEETS",
}

export interface CoverManagerIntegrationConfig {
  integration_type: IntegrationType.COVERMANAGER;
  slug: string;
}

export type IntegrationConfig = CoverManagerIntegrationConfig;
//         RestooIntegrationConfig |
//         Whatsapp360DIntegrationConfig |
//         SMSTwilioIntegrationConfig |
//         SMSNetelipIntegrationConfig |
//         GolfManagerIntegrationConfig |
//         GoogleMapsIntegrationConfig

export interface Integration {
  id: string;
  name: string;
  owner_id: string;
  config: IntegrationConfig;
}

type VoiceConfig = Partial<Record<LanguageEnum, VoiceSettings>>;

export interface BasicConfig {
  default_language: LanguageEnum | string;
  integrations: Integration[];
  voice: VoiceConfig;
}

export interface IRestaurantBotConfig {
  bot_type: BotType.RESTAURANT;
  display_name: string;
  phonetic_name: string;
  // faltan varias properties
}

export interface IFlexBotConfig extends BasicConfig {
  bot_type: BotType.FLEXBOT | string;
  steps: Record<string, IStep>;
  entry_step_id: string;
  entity_extraction_defaults: Record<EntityType, EntityMetadata>;
  // Set default metadata to use in all extraction actions for a given entity type.
  nlg_defaults: Record<EntityType, EntityMetadata>;
  interruptions: any;
  // Set default metadata to use in all NLG actions for a given entity type.
}

export interface IBot {
  id: string;
  owner_id: string;
  name: string;
  // User friendly-name for this bot.
  config: IRestaurantBotConfig | IFlexBotConfig;
}
