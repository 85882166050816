/* eslint-disable */
import { Button, Col, Popover, Row, Tooltip } from "antd";
import { FC } from "react";
import "./styles.css";
import { ChevronRight } from "react-feather";
import { fontWeight, width } from "@doar/shared";
import { t } from "i18next";
import { HomeOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";

interface CustomerDropdownProps {
  collapsed?: boolean;
}

const ContactUs: FC<CustomerDropdownProps> = ({ collapsed }) => {
  if (collapsed) {
    return null; // Retorna `null` si `collapsed` es `true`
  }

  const content = (
    <Col style={{ width: "200px" }}>
      <div className="contact-us-popover-item">
        <Tooltip title="search">
          <Button type="primary" shape="circle" icon={<PhoneOutlined />} />
        </Tooltip>
        <div className="contact-us-popover-item-text">
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>Phone</span>
          <span style={{ fontSize: "12px" }}>(+34) 933803348</span>
        </div>
      </div>

      <a href="mailto:soporte@bookline.ai" style={{ color: "inherit", textDecoration: "none" }}>
        <div className="contact-us-popover-item">
          <Tooltip title="search">
            <Button type="primary" shape="circle" icon={<MailOutlined />} />
          </Tooltip>
          <div className="contact-us-popover-item-text">
            <span style={{ fontWeight: "bold", fontSize: "14px" }}>Email</span>
            <span style={{ fontSize: "12px" }}>soporte@bookline.ai</span>
          </div>
        </div>
      </a>

      <div className="contact-us-popover-item">
        <Tooltip title="search">
          <Button type="primary" shape="circle" icon={<HomeOutlined />} />
        </Tooltip>
        <div className="contact-us-popover-item-text">
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>WhatsApp</span>
          <a href="https://wa.me/34633803348" target="_blank" rel="noopener noreferrer" style={{ fontSize: "12px", color: "inherit", textDecoration: "none" }}>
            933 803 348
          </a>
        </div>
      </div>
    </Col>
  );

  return (
    !collapsed && (
      <div className="contact-us-wrapper">
        <h3 style={{ color: "white" }}>{t("need_help")}</h3>
        <Popover content={content} trigger="click" placement="right">
          <Button type="primary">
            <span style={{ display: "flex", alignItems: "center", gap: "8px", fontWeight: "bold" }}>
              {t("contact_us")}
              <ChevronRight />
            </span>
          </Button>
        </Popover>
      </div>
    )
  );
};

export default ContactUs;
