/* eslint-disable */
/* eslint-disable import/no-unresolved */
export enum ContextVar {
  LANGUAGE = "LANGUAGE",
  MOTIVE = "MOTIVE",
  BOT_ID = "BOT_ID",
  USER_ID = "USER_ID",
  USER_PHONE = "USER_PHONE",
  LOCAL_CLIENT_DATETIME = "LOCAL_CLIENT_DATETIME",
  CONVERSATION_ID = "CONVERSATION_ID",
  LATEST_USER_INPUT = "LATEST_USER_INPUT",
  STEP_BEFORE_INTERRUPTION = "STEP_BEFORE_INTERRUPTION",
}

export enum EntityType {
  RAW_TEXT = "RAW_TEXT",
  KEYWORD = "KEYWORD",
  DATE = "DATE",
  DATETIME = "DATETIME",
  TIME = "TIME",
  NUMBER = "NUMBER",
  NAME = "NAME",
  CONFIRMATION = "CONFIRMATION",
  REGEX = "REGEX",
  PHONE_NUMBER = "PHONE_NUMBER",
}
