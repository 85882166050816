import axios from "axios";
import { auth } from "@doar/main/src/config/firebase";


// console.log(process.env);
let baseURL = "";
if (process.env.REACT_APP_ENVIRONMENT === "development") {
    baseURL = "https://papi.dev.bookline.io";
} else if (process.env.REACT_APP_ENVIRONMENT === "production") {
    baseURL = "https://papi.bookline.io";
}

const API = axios.create({
    baseURL,
    // baseURL: "https://papi.dev.bookline.io",
    // baseURL: "http://localhost:5000",
});

API.interceptors.request.use(
    async (config) => {
        const token = await auth.currentUser?.getIdToken();
        if (token) {
            let configUtil = { ...config.headers };
            delete configUtil.Authorization;
            configUtil["Authorization"] = `Bearer ${token}`;
            config.headers = { ...configUtil };
        }

        return config;
    },
    (error) => Promise.reject(error)
);

API.interceptors.response.use(
    (response) => response,
    async (error) => {
        const config = error?.config;
        if (
            (error?.response?.status === 0 ||
                error?.response?.status === 401) &&
            !config?.sent
        ) {
            config.sent = true;
            return API.request(config);
        }
        return Promise.reject(error);
    }
);

export default API;
