/* eslint-disable */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  EntityMetadata,
  KeywordMatchSelectionStrategy,
  PhoneNumberMetadataType
} from "src/components/create-bot/models/actions/extract_entity";
import { EntityType } from "src/components/create-bot/models/data";
import { IStep } from "src/components/create-bot/models/steps";

/* eslint-disable @typescript-eslint/dot-notation */
import { createSlice, current } from "@reduxjs/toolkit";

import {
  BotType,
  IBot,
  IFlexBotConfig
} from "../../components/create-bot/models";

const entityExtractionDefaults: Record<EntityType, EntityMetadata> = {
    RAW_TEXT: {
        entity_type: EntityType.RAW_TEXT,
    },
    KEYWORD: {
        entity_type: EntityType.KEYWORD,
        keywords: [],
        match_selection: KeywordMatchSelectionStrategy.FIRST,
    },
    DATE: {
        entity_type: EntityType.DATE,
    },
    DATETIME: {
        entity_type: EntityType.DATETIME,
    },
    TIME: {
        entity_type: EntityType.TIME,
        pm_assumption_hours: [],
    },
    NUMBER: {
        entity_type: EntityType.NUMBER,
    },
    NAME: {
        entity_type: EntityType.NAME,
    },
    CONFIRMATION: {
        entity_type: EntityType.CONFIRMATION,
    },
    REGEX: {
        entity_type: EntityType.REGEX,
        pattern: "",
    },
    PHONE_NUMBER: {
        entity_type: EntityType.PHONE_NUMBER,
        number_type: PhoneNumberMetadataType.VALID,
    },
};
const nlgDefaults: Record<EntityType, EntityMetadata> = {
    RAW_TEXT: {
        entity_type: EntityType.RAW_TEXT,
    },
    KEYWORD: {
        entity_type: EntityType.KEYWORD,
        keywords: [],
        match_selection: KeywordMatchSelectionStrategy.FIRST,
    },
    DATE: {
        entity_type: EntityType.DATE,
    },
    DATETIME: {
        entity_type: EntityType.DATETIME,
    },
    TIME: {
        entity_type: EntityType.TIME,
        pm_assumption_hours: [],
    },
    NUMBER: {
        entity_type: EntityType.NUMBER,
    },
    NAME: {
        entity_type: EntityType.NAME,
    },
    CONFIRMATION: {
        entity_type: EntityType.CONFIRMATION,
    },
    REGEX: {
        entity_type: EntityType.REGEX,
        pattern: "",
    },
    PHONE_NUMBER: {
        entity_type: EntityType.PHONE_NUMBER,
        number_type: PhoneNumberMetadataType.VALID,
    },
};
const steps: any = {};
const newBotConfig: IFlexBotConfig = {
    bot_type: BotType.FLEXBOT,
    steps,
    entry_step_id: "",
    entity_extraction_defaults: entityExtractionDefaults,
    nlg_defaults: nlgDefaults,
    default_language: "",
    integrations: [],
    voice: {},
    interruptions: {},
};
const bot: IBot = {
    id: "",
    owner_id: "",
    name: "",
    config: newBotConfig,
};

export interface UIState {
    bot: any;
    step?: any;
    editModeState: boolean;
}

const initialState: UIState = {
    bot,
    step: {},
    editModeState: false,
};

const botUISlice = createSlice({
    name: "botUI",
    initialState,
    reducers: {
        setBot(state, newBot: { payload: any; type: any }) {
            state.bot = newBot.payload;
        },
        patchStep(state, newValue: { payload: any; type: any }) {
            state.bot.config.steps[newValue.payload.id] = newValue.payload;
        },
        patchStepList(state, newStepList: { payload: any; type: any }) {
            
            

            state.bot.config.steps = newStepList.payload;
        },
        setStep(state, newStep: { payload: any; type: any }) {
            state.step = newStep.payload;
        },
        toggleEditMode(state) {
            state.editModeState = !state.editModeState;
        },
    },
});

export const {
    setStep,
    setBot,
    patchStep,
    patchStepList,
    toggleEditMode,
} = botUISlice.actions;
export default botUISlice.reducer;
