/* eslint-disable */
/* eslint-disable import/no-unresolved */
import React, { useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate } from "react-router-dom";
import { StyledLoader } from "src/components/preloader/style";
import { auth } from "src/config/firebase";

import { Spinner } from "@doar/components";
import { CustomerContext } from "./userContext";

// interface Props {
//     component: React.ReactChild;
// }

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { customer } = useContext(CustomerContext);
  const [user, loading] = useAuthState(auth);

  // console.log("CUSTOMER EN PROTECTED >>>>", customer);

  const authed = auth.currentUser != null;
  if (loading) {
    return (
      <StyledLoader>
        <Spinner size="lg" color="primary" />
      </StyledLoader>
    );
  }
    if (authed) {
        return customer?.onboarding?.status === "pending" ? <Navigate to="/onboarding" /> :  children;
    }
    return <Navigate to="/signin" />;
};

export default ProtectedRoute;
