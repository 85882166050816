/* eslint-disable */
/* eslint-disable import/no-unresolved */
import { EntityType } from "../data";
import { LanguageEnum } from "../language";
import { BaseAction } from "./common";

export interface IRawTextMetadata {
    entity_type: EntityType;
}

export enum KeywordMatchSelectionStrategy {
    FIRST = "FIRST",
}

export interface IKeywordMetadata {
    entity_type: EntityType;
    keywords: Array<string> | Record<LanguageEnum, Array<string>>;
    // Allows different values for different languages.
    match_selection: KeywordMatchSelectionStrategy;
    // Decide the strategy to use in case of multiple keyword matches.
}

export interface IDateMetadata {
    entity_type: EntityType;
}

export interface IDatetimeMetadata {
    entity_type: EntityType;
}

export interface ITimeMetadata {
    entity_type: EntityType;
    pm_assumption_hours: Array<number>;
    // Set of hours that, if detected without a specific timeperiod, will be translated to their pm equivalents.
}

export interface INumberMetadata {
    entity_type: EntityType;
}

export interface INameMetadata {
    entity_type: EntityType;
}

export interface IConfirmationMetadata {
    entity_type: EntityType;
}

export interface IRegexMetadata {
    entity_type: EntityType;
    pattern: string | Record<LanguageEnum, string>;
    // Allows different values for different languages.
}

export enum PhoneNumberMetadataType {
    MOBILE = "MOBILE",
    VALID = "VALID",
}

export interface IPhoneNumberMetadata {
    entity_type: EntityType;
    number_type: PhoneNumberMetadataType;
    // Decide whether to accept only mobile numbers or any valid (non-hidden) number.
}

export type EntityMetadata =
    | IRawTextMetadata
    | IKeywordMetadata
    | IDateMetadata
    | IDatetimeMetadata
    | ITimeMetadata
    | INumberMetadata
    | INameMetadata
    | IConfirmationMetadata
    | IRegexMetadata
    | IPhoneNumberMetadata;

export interface IExtractEntityAction extends BaseAction {
    entity_type: EntityType;
    entity_metadata: EntityMetadata;
    failure_next_step_id: string;
}
