/* eslint-disable */
/* eslint-disable import/no-unresolved */
import { FC, useContext, useState } from "react";
import { ChevronDown } from "react-feather";
import { CustomerContext, UserContext } from "src/config/userContext";
import { Dropdown, Tooltip, MenuProps } from "antd";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";

interface CustomerDropdownProps {
  collapsed?: boolean;
}

const CustomerDropdown: FC<CustomerDropdownProps> = ({ collapsed }) => {
  const { user } = useContext(UserContext);
  const { customer, customerUpdate } = useContext(CustomerContext);
  const { t } = useTranslation();
  const [tooltipVisible, setTooltipVisible] = useState(false); 

  const handleClick = (customer1: any) => {
    customerUpdate(customer1);
    const stringCust = JSON.stringify(customer1);
    sessionStorage.setItem("customerSelected", stringCust);
  };

  const customerMenuItems: MenuProps["items"] = user?.customers?.map((customer2: any) => ({
    key: customer2.id,
    label: (
      <>
        <span>{t("account")}</span>
        <span
          className={`${customer2.id === customer.id ? "selected" : ""}`}
          onClick={() => handleClick(customer2)}
          style={{
            fontSize: "14px",
            color: "#1890ff",
            display: "block",
            textAlign: "left",
            borderRadius: collapsed ? "8px" : "4px",
            height: "auto",
            margin: "0",
            justifyContent: collapsed ? "space-between" : "flex-start",
            alignItems: collapsed ? "center" : "initial",
            padding: collapsed ? "0px" : "10px 15px",
            width: "100%",
            cursor: "pointer",
          }}
        >
          {customer2?.name}
        </span>
      </>
    ),
  }));

  const menu = { items: customerMenuItems };

  const MAX_LENGTH = 14;

  const getCustomerDisplayName = (name: string) => {
    if (!name) return "";
    return name.length > MAX_LENGTH ? name.slice(0, MAX_LENGTH) + "..." : name;
  };

  const getCustomerInitial = (name: string) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };

  return (
    <Dropdown menu={menu} trigger={["click"]}>
      <Tooltip
        title={customer?.name || customer?.id} 
        open={tooltipVisible} 
        onOpenChange={(open) => setTooltipVisible(open)}
      >
        <div
          className={`${collapsed ? "collapsed-customer-dropdown" : "default-customer-dropdown"}`}
          onClick={() => setTooltipVisible(false)} // Oculta el tooltip al hacer clic
        >
          {collapsed ? getCustomerInitial(customer?.name || customer?.id) : getCustomerDisplayName(customer?.name || customer?.id)}
          <DownOutlined />
        </div>
      </Tooltip>
    </Dropdown>
  );
};

export default CustomerDropdown;
