/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { ApiOutlined, CloseOutlined, CommentOutlined, HistoryOutlined, HomeOutlined, LeftOutlined, MenuOutlined, PieChartOutlined, RightOutlined, SettingOutlined, BarChartOutlined } from "@ant-design/icons";
import { Button, Layout, Menu, Drawer, Alert, Tooltip } from "antd";
import { logout } from "src/config/firebase";
import { useWindowSize } from "@doar/shared/hooks";
import { Link, useNavigate } from "react-router-dom";
import { CustomerContext, UserContext } from "src/config/userContext";
import logo from "@doar/shared/images/bookline_v7_transparent_white.png";
import iconLogo from "@doar/shared/images/white-logo-transparent.png";
import ContentSearch from "src/components/aside-layout/content-search";
import { setCall, setCallsArr } from "src/redux/slices/calls-ui";
import { useAppDispatch } from "src/redux/hooks";
import { useTranslation } from "react-i18next";
import ProfileImage from "src/components/shared/profile-image";
import CustomerDropdown from "src/components/shared/customer-dropdown";
import API from "@doar/shared/methods/api";
import "./styles.css";
import { StyledDropDown } from "../layout-02/content-header/style";
import ContactUs from "src/components/shared/contact-us";

const { Header, Sider, Content } = Layout;

const AntLayout: React.FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [customerSearchList, setCustomerSearchList] = useState([]);
  const [userUtil, setUserUtil] = useState<any>();
  const { customer, customerUpdate } = useContext(CustomerContext);
  const { user, userUpdate } = useContext(UserContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [botSearchList, setBotSearchList] = useState([]);
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { i18n, t } = useTranslation();
  const [showBanner, setShowBanner] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [trialDays, setTrialDays] = useState(9);
  const [showButton, setShowButton] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const isMobile = width && width < 768;
  const userRole = userUtil?.user?.role;
  const [showCollapseButton, setShowCollapseButton] = useState(false);

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  function handleLogout(e: any) {
    e.preventDefault();
    logout();
    userUpdate({
      auth_uid: "defaultUid",
      email: "default@email.com",
      id: "defaultId",
      name: "defaultName",
      permissions: [],
      phone: {},
      role: "CLIENT",
      sip_settings: {},
    });
    customerUpdate({
      id: "defaultCustomer",
      name: "defaultName",
      accounts: [{}],
    });
    dispatch(setCallsArr([]));
    dispatch(setCall({ payload: {}, type: "" }));
    sessionStorage.removeItem("customerSelected");
  }

  useEffect(() => {
    setUserUtil(user);
  }, [user]);

  function getAccounts(expired: boolean) {
    let havePaymentMethod = false;
    if (customer.accounts) {
      havePaymentMethod = checkPaymentMethod(customer.accounts);
      if (havePaymentMethod && expired) {
        setShowBanner(false);
        setModalOpen(false);
        setShowButton(false);
      } else if (expired) {
        setShowBanner(false);
        setModalOpen(true);
        setShowButton(false);
        navigate("/settings");
      } else {
        setShowBanner(true);
      }
    } else {
      API.get(`/customers/${customer.id}/accounts`)
        .then((res) => {
          let customerUtil = JSON.parse(JSON.stringify(customer));
          customerUtil["accounts"] = res.data.data;
          customerUpdate(customerUtil);
          havePaymentMethod = checkPaymentMethod(res.data.data);
          if (havePaymentMethod && expired) {
            setShowBanner(false);
            setModalOpen(false);
            setShowButton(false);
          } else if (expired) {
            setShowBanner(false);
            setModalOpen(true);
            setShowButton(false);
            navigate("/settings");
          } else {
            setShowBanner(true);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  useEffect(() => {
    if (customer && customer.id !== "defaultCustomer") {
      if (customer.trial_end_date) {
        const endDate: any = new Date(customer.trial_end_date);
        const today: any = new Date();
        const timeDiff = endDate - today;
        const daysDiff: number = Math.floor(timeDiff / (24 * 60 * 60 * 1000));

        if (customer?.trial_end_date && endDate >= today && !customer?.trial_start_date) {
          // PARA CUANDO SE HABILITE EL BOTON
          // setShowButton(true);
          setShowButton(false);
        } else if (customer?.trial_start_date && customer?.trial_end_date) {
          const startDate: any = new Date(customer.trial_start_date);
          if (endDate >= today && today >= startDate) {
            console.log("periodo de prueba");
            setShowBanner(true);
            setTrialDays(daysDiff);
          } else if (today > endDate) {
            getAccounts(true);
            // setModalOpen(true);
          }
        }
      } else {
        setShowBanner(false);
        setModalOpen(false);
        setShowButton(false);
      }
    }
  }, [customer]);

  function checkPaymentMethod(accounts: any) {
    return accounts.some((acc: any) => {
      return acc?.payment_details ? true : false;
    });
  }

  function getInput(value: string) {
    if (value.length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
    let customerList = window.sessionStorage.getItem("customerList");
    let botList = window.sessionStorage.getItem("botList");
    if (customerList) {
      let customerUtil = JSON.parse(customerList).filter((customer: { id: string; name: string }) => {
        return customer.id.toLowerCase().includes(value) || customer?.name.toLowerCase().includes(value);
      });
      // .map((customer: { id: string; name: string }) => {
      //     return `${customer.id} (${customer?.name})`;
      // });
      setCustomerSearchList(customerUtil);
    }
    if (botList) {
      let botUtil = JSON.parse(botList)
        .filter((bot: { id: string; name: string }) => {
          return bot.id.toLowerCase().includes(value) || bot?.name.toLowerCase().includes(value);
        })
        .map((bot: { id: string; name: string }) => {
          return `${bot.id} (${bot?.name})`;
        });
      setBotSearchList(botUtil);
    }
  }

  function handleCustomer(customer: any) {
    setShowSearch(false);

    customerUpdate(customer);

    sessionStorage.setItem("customerSelected", JSON.stringify(customer));
  }

  useEffect(() => {
    if (!showSearch) {
      console.log("showSearch está en false");
      // Aquí puedes agregar cualquier acción adicional que quieras hacer
      // cuando showSearch sea false, si es necesario.
    }
  }, [showSearch]);

  function handleClickOutside() {
    setTimeout(() => {
      setShowDropdown(false);
    }, 500);
  }

  const handleToggleSearch = (value: boolean) => {
    setShowSearch(value);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const menuItems = [
    {
      key: "1",
      icon: <PieChartOutlined />,
      label: "Dashboard",
      onClick: () => navigate("/"),
      roles: ["ADMIN"],
    },
    {
      key: "2",
      icon: <HomeOutlined />,
      label: t("venues"),
      onClick: () => navigate("/locales"),
      roles: ["ADMIN"],
    },
    {
      key: "3",
      icon: <CommentOutlined />,
      label: t("channel_setup"),
      onClick: () => navigate("/channels"),
      roles: ["ADMIN"],
    },
    {
      key: "4",
      icon: <HistoryOutlined />,
      label: t("activity"),
      items: [
        { key: "4-1", label: t("calls"), onClick: () => navigate("/conversations") },
        { key: "4-2", label: t("chats"), onClick: () => navigate("/chats") },
        { key: "4-3", label: t("live_calls"), onClick: () => navigate("/live-calls") },
      ],
      roles: ["ADMIN", "CLIENT", "PARTNER"],
    },
    {
      key: "5",
      icon: <ApiOutlined />,
      label: t("integrations"),
      onClick: () => navigate("/integrations"),
      roles: ["ADMIN", "CLIENT", "PARTNER"],
    },
    {
      key: "6",
      icon: <BarChartOutlined />,
      label: t("reports"),
      onClick: () => navigate("/reports"),
      roles: ["ADMIN"],
    },
    {
      key: "7",
      icon: <SettingOutlined />,
      label: t("settings"),
      items: [
        { key: "7-1", label: t("billing"), onClick: () => navigate("/billing") },
        { key: "7-2", label: t("team"), onClick: () => navigate("/team") },
      ],
      roles: ["ADMIN", "CLIENT", "PARTNER"],
    },
   
  ];
  const filteredMenuItems = menuItems.filter((item) => item.roles.includes(userRole));

  return (
    <Layout>
      {/* Para pantallas grandes usamos Sider */}
      {!isMobile && (
        <Sider trigger={null} collapsible collapsed={collapsed} onMouseEnter={() => setShowCollapseButton(true)} onMouseLeave={() => setShowCollapseButton(false)}>
          <div
            style={{
              height: "64px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#212131",
              marginBottom: "16px",
              zIndex: "9999",
            }}
          >
            {/* Logo grande cuando el Sider está expandido y pequeño cuando está colapsado */}
            <Link to="/">
              {/* Logo grande cuando el Sider está expandido y pequeño cuando está colapsado */}
              {!collapsed ? <img src={logo} alt="Bookline logo" style={{ height: "35px" }} /> : <img src={iconLogo} alt="Bookline Icon" style={{ height: "45px" }} />}
            </Link>
          </div>
          <Menu style={{ backgroundColor: "#212131" }} mode="inline">
            <CustomerDropdown collapsed={collapsed} />
            {filteredMenuItems.map((item) =>
              item.items ? (
                <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
                  {item.items.map((subItem) => (
                    <Menu.Item key={subItem.key} onClick={subItem.onClick}>
                      {subItem.label}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ) : (
                <Menu.Item key={item.key} icon={item.icon} onClick={item.onClick}>
                  {item.label}
                </Menu.Item>
              )
            )}
          </Menu>
          <div style={{ position: "absolute", left: "22px", bottom: "100px", width: collapsed ? "50%" : "80%" }}>
            <ContactUs collapsed={collapsed}></ContactUs>
          </div>
          <div style={{ position: "absolute", left: "22px", bottom: "24px", borderTop: "1px solid #51517E", width: collapsed ? "50%" : "80%" }}>
            <ProfileImage collapsed={collapsed}></ProfileImage>
          </div>
          {/* Colapse button */}
          {showCollapseButton && (
            <Tooltip title={collapsed ? t("expand") : t("collapse")} placement="right">
              <Button
                type="text"
                icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
                onClick={() => {
                  if (isMobile) {
                    toggleDrawer();
                  } else {
                    setCollapsed(!collapsed);
                  }
                }}
                className="collapse-button"
              />
            </Tooltip>
          )}
        </Sider>
      )}

      {/* Para pantallas móviles usamos Drawer */}
      {isMobile && (
        <>
          <Header
            style={{
              backgroundColor: "#212131",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0 16px",
              height: "64px",
              zIndex: 99999,
              position: 'fixed',
              top: 0,
              left: 0,
              width: "100vw",
            }}
          >
            <Link to="/">
              <img src={logo} alt="Bookline logo" style={{ height: "35px" }} />
            </Link>
            <Button
              type="text"
              icon={
                drawerVisible ? (
                  <CloseOutlined className="icon-animation" style={{ color: "white" }}/>
                ) : (
                  <MenuOutlined className="icon-animation" style={{ color: "white" }}/>
                )
              }
              onClick={toggleDrawer}
            />
          </Header>
          <Drawer
            placement="left"
            closable={false}
            onClose={toggleDrawer}
            open={drawerVisible}
            width={"30vh"}
            style={{ backgroundColor: "#212131", zIndex: "999", marginTop: 64}}
            className="layout-drawer-wrapper"
          >
            <Menu mode="inline" style={{ backgroundColor: "#212131" }}>
              <div style={{marginBottom: 24}}>
                <CustomerDropdown collapsed={collapsed}></CustomerDropdown>
              </div>
              {menuItems.map((item) =>
                item.items ? (
                  <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
                    {item.items.map((subItem) => (
                      <Menu.Item key={subItem.key} onClick={subItem.onClick}>
                        {subItem.label}
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                ) : (
                  <Menu.Item key={item.key} icon={item.icon} onClick={item.onClick}>
                    {item.label}
                  </Menu.Item>
                )
              )}
              <div style={{ position: "absolute", bottom: "24px", left: "22px"}}>
                <ProfileImage collapsed={collapsed}></ProfileImage>
              </div>
            </Menu>
          </Drawer>
        </>
      )}

      {/* Banner si tiene el periodo de prueba activado */}
      {showBanner && (
        <Alert
          className="banner"
          action={
            <button onClick={() => navigate(`/settings`, { state: { tab: "1" } })} className="bannerButton">
              Agregar método de pago
            </button>
          }
          message={`El período de prueba finaliza en ${trialDays} días.${window.innerWidth > 768 ? " Por favor agrega un medio de pago para completar la suscripción." : ""}`}
          banner
          showIcon={false}
          closable
        />
      )}

      <Layout>
        {userUtil?.user?.role === "ADMIN" && <ContentSearch getInput={getInput} toggleSearch={handleToggleSearch} />}
        {showDropdown && (
          <StyledDropDown>
            <strong>Customers:</strong>
            <br />
            {customerSearchList.map((el: { id: string; name: string; accounts: any[] }) => {
              return (
                <div className="itemSelect" role="button" tabIndex={0} key={el.id} onClick={() => handleCustomer(el)}>
                  {/* <Link to={`/bot/${el.split("(")[0].trim()}`}> */}
                  {`${el.id} (${el?.name})`}
                  {/* </Link> */}
                </div>
              );
            })}
            <br />
            <strong>Bots:</strong>
            <br />
            {botSearchList.map((el: string) => {
              return (
                <div
                  className="itemSelect"
                  role="button"
                  tabIndex={0}
                  key={el}
                  onClick={() => {
                    navigate(`/newBotEdit/${el.split("(")[0].trim()}`);
                  }}
                >
                  {/* <Link to={`/bot/${el.split("(")[0].trim()}`}> */}
                  {el}
                  {/* </Link> */}
                </div>
              );
            })}
          </StyledDropDown>
        )}
        <Content
          style={{
            padding: 24,
            minHeight: 280,
            marginTop: isMobile ? 54 : 0,
            background: "#fff",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AntLayout;
